import Vue from 'vue'
import App from './App.vue'
import router from './router/index'
import store from './store'
import ElementUI from 'element-ui';
import * as echarts from "echarts";
import 'element-ui/lib/theme-chalk/index.css';
import VueRouter from 'vue-router'
import './permission';
import "./icons"; // icon
import "@/styles/common.scss"; // global css
Vue.use(ElementUI);
Vue.prototype.$echarts = echarts;
Vue.prototype.$store = store;
Vue.config.productionTip = false;
Vue.use(VueRouter)

router.beforeEach((to, from, next) => {
  if (from.path == '/') {
    let flag = localStorage.getItem('token') ? true : false
    store.commit('SETISLOGIN', flag)
  }
  next()
})

router.afterEach(() => {
  window.scrollTo(0, 0);
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
