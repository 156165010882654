module.exports = {

    title: '旅友',
    loginTitle: '旅友',
    /**
     * @type {String} sidebar | transverse | columns | mixture
     * @description 菜单布局样式
     */
    layout: 'transverseMixtureMenu',
    /**
     * @type {boolean} true | false
     * @description 是否显示设置右侧面板
     */
    showSettings: false,
    /**
     * @type {boolean} true | false
     * @description 是否显示面包屑
     */
    showBreadcrumb: false,
    /**
     * @type {boolean} true | false
     * @description 是否显示页面标签
     */
    tagsView: false,

    /**
     * @type {boolean} true | false
     * @description 固定头部
     */
    fixedHeader: true,

    /**
     * @type {boolean} true | false
     * @description 是否在侧边栏显示Logo
     */
    sidebarLogo: true,

    /**
     * @type {string | array} 'production' | ['production', 'development']
     * @description Need show err logs component.
     * The default is only used in the production env
     * If you want to also use it in dev, you can pass ['production', 'development']
     */
    errorLog: 'production',

    /**
     * token头
     */
    tokenHead: 'Bearer ',
    /**
     * 认证请求头
     */
    authorization: 'Authorization'

  }
