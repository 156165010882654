<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
// import Nprogress from ''
export default {

}
</script>
<style lang="scss">
// 图片上传满了隐藏按钮
.hideUploadBtn .el-upload--picture-card {
  display: none !important;
}

.overfH {
  overflow: hidden;
  white-space: nowrap;
  font-size: 14px;
}

.el-image {
  border-radius: 5px;
}

.borRadus5 {
  border-radius: 5px;
}

// 修改 confirm的颜色
.elConfirmBtn {
  .el-button:nth-child(1) {
    &:hover {
      color: var(--primary-color);
      border-color: var(--btn-hover-bg);
      background-color: var(--hover-color);
    }

    &:focus {
      color: var(--primary-color);
      border-color: var(--btn-hover-bg);
      background-color: var(--hover-color);
    }
  }

  .el-button:nth-child(2) {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
    color: #fff;

    &:hover {
      background-color: var(--primary-color);
      border-color: var(--primary-color);
      opacity: 0.8;
    }

    &:focus {
      background-color: var(--primary-color);
      border-color: var(--primary-color);
    }

    &:active {
      background-color: var(--primary-color);
      border-color: var(--primary-color);
    }

    &:disabled {
      opacity: 0.5;
    }
  }
}


body {
  min-width: 1400px;
}

// 全局设置表头颜色
.el-table th {
  font-weight: 400;
  color: var(--word-color);
  font-size: 16PX;
  background: rgba(10, 25, 51, 0.03) !important;
}

.el-table .el-table__row .cell {
  color: var(--word-color);
}

// 设置滚动条宽高
::-webkit-scrollbar {
  width: 4px;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(144, 147, 153, 0.3);
  border-radius: 5px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
}

// .el-button--primary {
//   background-color: var(--primary-color);
// }


#app {
  padding: 0;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--word-color);
  margin: 0 auto;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: var(--word-color);

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

// 去掉 el-input number 右边剪头
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type="number"] {
  -moz-appearance: textfield;
}

inpit {
  border: none
}

// dialog居中显示（开始）
.el-dialog {
  display: flex;
  flex-direction: column;
  margin: 0 !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  // min-height: 460px;
}

.el-dialog .el-dialog__body {
  flex: 1;
  overflow: auto;
  max-height: calc(100vh - 150px - 70px)
}

.dialog-footer {
  display: flex;
  justify-content: center;
  align-items: center;
}

// dialog居中显示（结束）

// flex 从左往右布局
.flexRow {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

// 按钮修改颜色
.wl-button {
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
  color: #fff;

  &:hover {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
    opacity: 0.8;
  }

  &:focus {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
  }

  &:active {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
  }

  &:disabled {
    opacity: 0.5;
  }
}

// 默认按钮效果
.default-btn.el-button {
  &:hover {
    color: var(--primary-color);
    border-color: var(--btn-hover-bg);
    background-color: var(--hover-color);
  }

  &:focus {
    color: var(--primary-color);
    border-color: var(--btn-hover-bg);
    background-color: var(--hover-color);
  }
}
</style>
