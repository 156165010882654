import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: HomeView,
    children: [
      {
        path: "/",
        component: () => import("@/views/DashBoard/index.vue"),
        name: "Home"
      },
      {
        path: '/super',
        component: () => import('@/views/SuperMaket/index.vue'),
        name: 'Super'
      },
      {
        path: '/prd',
        component: () => import('@/views/Product/index.vue'),
        name: 'Product',
      },
      {
        path: '/my',
        component: () => import('@/views/My/index.vue'),
        name: 'My',
      },
      {
        path: '/detail',
        component: () => import('@/views/SuperMaket/detail.vue'),
        name: 'Detail',
      },
      {
        path: '/guide',
        component: () => import('@/views/ShoppingMall/guide.vue'),
        name: 'Guide',
      },
      {
        path: '/consultation',
        component: () => import('@/views/WlConsultation/consultation.vue'),
        name: 'Consultation',
      }

    ]
  }
]


const router = new VueRouter({
  routes
})

export default router
