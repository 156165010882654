import router from './router'
import store from './store'
import Vue from 'vue'
import NProgress from 'nprogress' // Progress 进度条
import 'nprogress/nprogress.css' // Progress 进度条样式
// import { getToken } from '@/utils/auth' // 鉴权
// import getPageTitle from '@/utils/get-page-title'
// import { filterAsyncRouter } from '@/store/modules/permission'
NProgress.configure({ easing: 'ease', speed: 1000, showSpinner: true }) // NProgress配置

// const whiteList = ['/login'] // 不重定向白名单

router.beforeEach(async (to, from, next) => {
  NProgress.start()   // 开启进度条
  // some code ...
  next()

  // 设置页面标题
  // document.title = getPageTitle(to.meta.title)

  // 确定用户是否已登录

})

router.afterEach(() => {
  // finish progress bar
  NProgress.done()
})
